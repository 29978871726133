<template>
  <v-container fluid class="down-top-padding dashboard">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs" style=" border-bottom: 3px solid #dce2e8;"></BaseBreadcrumb>
    <v-row v-if="permissions.includes('is_superuser')">
      <v-col cols="12" sm="12" lg="12">
        <Dashboard />
      </v-col>
    </v-row>
    <v-row v-else >
      <v-col class="d-flex justify-center" >
        <v-img max-width="400"  :src="require('../../assets/images/ajolitos/ajolito-hechizero.png')"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  name: "StarterPage",
  components: {
    Dashboard: () => import('@/components/dashboardComponents/dashboard/Index.vue')
  },
  computed: {
    ...mapGetters(['permissions']),
  },
  data: () => ({
    page: {
      title: "Dashboard"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "Home",
        disabled: false
      },
      {
        text: "Dashboard",
        disabled: true
      }
    ]
  })
};
</script>

<style lang="scss" scoped>
.dashboard {
  background: #FFFFFF;
}
</style>
